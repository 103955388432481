import { UserData } from "./userdata";

export const getSession = async (): Promise<UserData> => {
    try {
        const session = await (window as any).cet?.accessmanagement?.getSessionAsync();
        return session || {};
    } catch (error) {
        console.error("Error:", error);
        return {};
    }
};
