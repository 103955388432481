import { UserData } from "./userdata";

export const sendEvent = async (userData: UserData, index: number) => {
    try {
        //const userAgent = window.navigator.userAgent;
        const currentReferrer = window.location.href;

        userData.role = userData.role || "guest";
        userData.userId = userData.userId || "00000000-0000-0000-0000-000000000000";

        const objectNames: { [key: number]: string } = {
            0: "sodmaya_myworks",
            1: "sodmaya_creation",
            2: "sodmaya_bible",
            3: "sodmaya_dubi",
            4: "sodmaya_music",
            5: "sodmaya_painting",
            6: "sodmaya_park",
            7: "sodmaya_hebrew",
            8: "sodmaya_math",
            9: "sodmaya_movies",
            10: "sodmaya_bible",
            11: "sodmaya_english",
            14: "sodmaya_holiday",
        };

        const objectName = objectNames[index] || "unknown";

        const contentGroupingId = (index + 1).toString();

        const optionalData: { [key: string]: any } = {
            currentHyperlink: currentReferrer,
            contentGroupingId: contentGroupingId,
            contentLanguage: "he",
        };

        if (userData.sessionId != null) {
            optionalData.sessionId = userData.sessionId;
        }

        if (userData.schoolId != null) {
            optionalData.schoolId = userData.schoolId;
        }

        (window as any).cet?.microservices?.bigData?.messages?.sendLearningEvent(
            "launched",
            objectName,
            objectName,
            "webpage",
            "sodmaya",
            currentReferrer,
            userData.role,
            userData.userId,
            optionalData
        );

        console.log("Event sent successfully.");
    } catch (error) {
        console.error("Error:", error);
    }
};
